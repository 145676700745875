export default [
    // {
    //   name:'home',
    //   link:'/'
    // },
    {
      name:'recepten',
      link:'/recipes'
    },
    {
      name:'weekmenu',
      link:'/weekmenu'
    },
    {
      name:'blog',
      link:'/blog'
    },
    {
      name:'over',
      link:'/about'
    },
    {
      name:'contact',
      link:'/contact'
    }
  ]
  