import React from 'react'
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import links from "../../constants/links";
import socialIcons from "../../constants/social-icons";
import { Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <Container fluid className="mt-auto p-4 text-center footer">
            <Container fluid>
                {links.map((link, index) => {
                    return <AniLink className="a-footer-nav d-inline-block mx-2 my-3" fade key={index} to={`${link.link}/`}>{link.name}</AniLink>
                })}
            </Container>
            <Container fluid>
                {socialIcons.map((item, index) => {
                    return (
                        <a
                            className="a-footer-social d-inline-block m-3"
                            key={index}
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.icon}
                        </a>
                    )
                })}
            </Container>
            <Container className="footer-copyright">
                <h6 className="h6-white">
                    Stefani Peters &copy; {new Date().getFullYear()} | 
                    <a
                    className="h6-white d-inline-block"
                    href="https://www.bystefani.nl"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                        ByStefani
                    </a>
                </h6>
            </Container>
        </Container>
    )
}

export default Footer;