import React, { useEffect } from "react";
// import NavBar from "../NavBar";
import { Container } from "react-bootstrap";
import TopNav from "./TopNav";
import Footer from "./Footer";
// import AOS from 'aos';

import "../../theme/styles.scss";
import 'aos/dist/aos.css';

import posthog from 'posthog-js';

// AOS.init();

const PageWrapper = ({ children }) => {
  let AOS;

  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
    posthog.init('rvIFTrc6IZnihUxYlkoc2vDpV99BLe-4YMCRnPC54D4', { api_host: 'https://app.posthog.com' })

  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Container fluid style={{ padding: "0" }}>
      <TopNav />
      {children}
      <Footer />
    </Container>
  )
}

export default PageWrapper
