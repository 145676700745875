import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import links from "../../constants/links";
// import socialIcons from "../../constants/social-icons";
import { graphql, Link, useStaticQuery } from "gatsby";
import { MdRestaurantMenu } from "react-icons/md"
const TopNav = () => {
    const [logoTopIsReached, logoTopReached] = useState()
    const [heroTopIsReached, heroTopReached] = useState()

    const data = useStaticQuery(graphql`
    {
        logo: file(relativePath: { eq: "LogoLarge.png" }) {
            name
            childImageSharp {
                fluid(quality: 100, maxHeight: 750) {
                  srcWebp
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          logoWhite: file(relativePath: { eq: "LogoLarge_White.png" }) {
            name
            childImageSharp {
                fluid(quality: 100, maxHeight: 750) {
                  srcWebp
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
    }
  `)

    useEffect(() => {
        document.addEventListener('scroll', trackScrolling);
    })

    const isTop = (el) => {
        return el.getBoundingClientRect().top < 0;
    }

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom < 0;
    }


    const trackScrolling = () => {
        const wrappedElement = document.getElementById('LogoContainer');
        const wrappedHero = document.getElementById('HeroContainer');
        if (wrappedElement) {
            logoTopReached(isTop(wrappedElement));
        }

        if (wrappedHero) {
            heroTopReached(isBottom(wrappedHero));
        }
    };
    const isDocument = typeof document !== `undefined`;
    const LogoContainer =isDocument ? document.getElementById('LogoContainer') : null;
    const HeroContainer = isDocument ? document.getElementById('HeroContainer') : null;
    return (
        isDocument && (
            <Navbar
                collapseOnSelect
                expand="md"
                bg={(heroTopIsReached || (!LogoContainer && !HeroContainer)) ? "light" : ""}
                className="navbar-custom pt-4"
                fixed="top"
            >
                {logoTopIsReached && (
                    <Link to="/">
                        <img
                            src={data.logo.childImageSharp.fluid.srcWebp}
                            width="auto"
                            height="50"
                            className="pb-2"
                            alt={data.logo.name}
                        />
                    </Link>
                )}
                {(!LogoContainer && !HeroContainer) && (
                    <Link to="/">
                        <img
                            src={data.logo.childImageSharp.fluid.srcWebp}
                            width="auto"
                            height="50"
                            className="pb-2"
                            alt={data.logo.name}
                        />
                    </Link>
                )}
                {(!LogoContainer && HeroContainer && !heroTopIsReached) && (
                    <Link to="/">
                        <img
                            src={data.logoWhite.childImageSharp.fluid.srcWebp}
                            width="auto"
                            height="50"
                            className="pb-2"
                            alt={data.logo.name}
                        />
                    </Link>
                )}
                {(!LogoContainer && HeroContainer && heroTopIsReached) && (
                    <Link to="/">
                        <img
                            src={data.logo.childImageSharp.fluid.srcWebp}
                            width="auto"
                            height="50"
                            className="pb-2"
                            alt={data.logo.name}
                        />
                    </Link>
                )}
                <Navbar.Toggle aria-controls="navbarResponsive" style={{ borderColor: "transparent" }} >
                    <MdRestaurantMenu
                        size="1.5em"
                        color={`
                    ${((!LogoContainer && HeroContainer && !heroTopIsReached) || (LogoContainer && HeroContainer && !logoTopIsReached)) ? "white" : "black"}`} />
                </Navbar.Toggle>
                <Navbar.Collapse id="navbarResponsive" className={`justify-content-center ${heroTopIsReached ? "bg-light" : ""}`} >
                    <Nav>
                        <ul className="navbar-nav justify-content-center">

                            {links.map(link => (
                                <li key={link.name}>
                                    <Link
                                        to={`${link.link}/`}
                                        className={`
                                        nav-link 
                                        px-5 
                                        py-3 
                                        ${(logoTopIsReached ||
                                                (!LogoContainer && !HeroContainer) ||
                                                (LogoContainer && !HeroContainer) ||
                                                (!LogoContainer && HeroContainer && heroTopIsReached))
                                                ? ""
                                                : "a-white"}
                                        `}
                                        activeClassName="active">
                                        {link.name.toUpperCase()}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Nav>
                    {/* <Nav>
                    <Row className="justify-content-center">
                    {socialIcons.map((item, index) => {
                        return (
                            <Nav.Link
                                key={index}
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-3"
                            >
                                {item.icon}
                            </Nav.Link>
                        )
                    })}
                    </Row>
                </Nav> */}
                </Navbar.Collapse>
            </Navbar>)
    )
}

export default TopNav;
