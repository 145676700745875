import React from 'react';
import { FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';
export default [
    {
        icon:  <FaInstagram />,
        url: 'https://www.instagram.com/stefanipeters/'
    },
    {
        icon:  <FaYoutube />,
        url: 'https://www.youtube.com/user/StefaniPeters24/'
    },
    {
        icon:  <FaLinkedin />,
        url: 'https://www.linkedin.com/in/stefani-peters/'
    }
]